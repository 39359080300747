import * as React from "react"
import Layout from "../layouts/default"
import Seo from "../components/seo"
import * as style from "../sass/pages/services.module.sass"
import { graphql } from "gatsby"
import { PortableText } from '@portabletext/react'


const ServicesPage = ({ data }) => {
  const page = data.allSanityServices.edges[0].node;
  return (
    <Layout>
      <Seo title="Services" />
      <section className={style.mainContent}>
        <PortableText
          value={page._rawHeader}
          components={{
            marks: {
              underline: ({ children }) => <u>{children}</u>,
            }
          }}
        />
        <PortableText
          value={page._rawBody}
        />
      </section>
      <section className={style.services}>
        <div>
          {
            page.services.map((service, index) => (
              <h2 key={index}>{service}</h2>
            ))
          }
        </div>
      </section>
    </Layout>
  )
}

export default ServicesPage


export const query = graphql`
{
  allSanityServices {
    edges {
      node {
        _rawHeader
        _rawBody
        services
      }
    }
  }
}
`